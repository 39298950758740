<template>
  <div class="cont">
    <el-row class="search-box">
      <el-row class="search-row" type="flex">
        <el-row class="search-item">
          <el-select v-model="paymentMethod" @change="processRecord">
              <el-option v-for="(select,key) in dictData['pay-method']" :key="key" :label="select" :value="key"></el-option>
          </el-select>
        </el-row>
        <el-row class="search-item m-left-10">
          <el-select v-model="itemType" @change="processRecord">
              <el-option v-for="(select,idx) in dictData['income-list']" :key="idx" :label="select.label" :value="select.value"></el-option>
          </el-select>
        </el-row>
        <el-row class="search-item m-left-10">
          <el-date-picker
            value-format="yyyy-MM-dd"
            @change="processRecord"
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <el-button type="text" class="m-left-10" @click="resetData">重置筛选</el-button>
        </el-row>
        <el-button type="primary" style="margin-left:auto" @click="handleExcel">报表导出</el-button>
      </el-row>
    </el-row>
    <!-- 表格 -->
    <el-table :data="tableData" border fit :stripe="true" style="margin-top:30px;">
      <el-table-column prop="itemType" label="项目" fit fixed min-width="40">
        <template slot-scope="scope">
          <span> {{ scope.row.itemType | itemTypeFilter(that) }} </span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="收款时间" fit fixed min-width="40"></el-table-column>
      <el-table-column prop="paymentMethod" label="支付方式" min-width="30">
        <template slot-scope="scope">
          <span> {{ dictData['pay-method'][scope.row.paymentMethod] }} </span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="收款金额" min-width="30"></el-table-column>
      <el-table-column prop="startTime" label="入住时间" min-width="30"></el-table-column>
      <el-table-column prop="refUserName" label="操作人" min-width="30"></el-table-column>
      <el-table-column prop="updateTime" label="操作时间" min-width="30"></el-table-column>
      <el-table-column prop="bookOrderNo" label="关联订单" min-width="30"></el-table-column>
      <el-table-column prop="realName" label="联系人" min-width="30"></el-table-column>
      <el-table-column prop="mobile" label="联系电话" min-width="30"></el-table-column>
    </el-table>
    <!-- 底部统计 -->
    <el-row type="flex" style="background:#FFF;padding:20px;">
      <div style="line-height:2;color:#585858">
        共计11条记录，净收款：<span style="color:#087FFD">￥{{totalData.netReceipts || 0}}</span> | 
        总收款： <span style="color:#FF9100">￥{{totalData.totalReceive || 0}}</span> |
        总退款： <span style="color:#FF0000"> ￥{{totalData.totalRefund || 0}}</span>
      </div>
      <pagination style="margin:0 0 0 auto;" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
    </el-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { dateFactory, getDict, exportFile } from '@/common/js/common'
import { processRecord, processRecordExcel,getTotal } from "@/api/pms/statistics/proceedsSummary";
export default {
  data() {
    return {
      that:this,
      paymentMethod:"",
      itemType:"",
      dateRange:[],
      page:1,
      limit:10,
      total:0,
      tableData:[],
      totalData:{}
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData']),
  },
  mounted() {
    getDict(['payment-method','income'],false)
    this.processRecord()
  },
  methods: {
    pageChange(limit){
      this.limit = limit
      this.processRecord()
    },
    handlePaging(page){
      this.page = page
      this.processRecord()
    },
    resetData(){
      this.paymentMethod = ""
      this.itemType = ""
      this.dateRange.length = 0
      this.page = 1
      this.limit = 10
      this.processRecord()
    },
    handleExcel(){
      let params = {
        page:this.page,
        limit:this.limit,
        hotelId:this.hotelInfo.id,
        paymentMethod:this.paymentMethod,
        itemType:this.itemType,
        beginDateStr:this.dateRange[0],
        endDateStr:this.dateRange[1]
      }
      processRecordExcel(params).then( res => {
        exportFile(res, "收款记录")
      })
    },
    getTotal(){ // 获取收款记录汇总
      const params = {
        hotelId: this.hotelInfo.id,
        companyId: this.hotelInfo.storeId,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1]
      }
      getTotal(params).then( res => {
        this.totalData = res.records
      })
    },
    processRecord(){ // 获取收款记录
      let params = {
        page:this.page,
        limit:this.limit,
        hotelId:this.hotelInfo.id,
        paymentMethod:this.paymentMethod,
        itemType:this.itemType,
        beginDateStr:this.dateRange[0],
        endDateStr:this.dateRange[1]
      }
      processRecord(params).then( res => {
        this.total = res.total
        this.tableData = res.records
      })
      this.getTotal()
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.processRecord()
      }
    }
  },
  filters:{
    itemTypeFilter(val,that){
      let str = ""
      that.dictData['income-list'].forEach(element => {
        if(val === element.value){
          str = element.label
        }
      });
      return str || val
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  .title {
    margin: 35px 0 10px 0;
    h3{
      font-size: 18px;
      span { color: #B9B9B9; font-weight: normal; font-size: 12px; margin-left: 6px }
    }
  }
  .card-box {
    background: #FFFFFF;
    dl {
      display: flex; align-items: center; padding: 30px 0 30px 30px;
      width: 33.33%; position: relative;
      dt{
        margin: 0 20px 0 0;
        .image { width: 48px; height: 48px; background-size: 100% 100% }
      }
      dt .image1 { background-image: url("../../../assets/make-note-icon1.png") }
      dt .image2 { background-image: url("../../../assets/make-note-icon2.png") }
      dt .image3 { background-image: url("../../../assets/make-note-icon3.png") }
      dd {
        p { font-size: 14px; color: #999999; margin-bottom: 10px }
        span{ font-size: 28px; color: #087FFD; }
      }
    }
    dl:before {
      position: absolute; content: ''; width: 1px;
      height: 60px; background-color: #CCCED9; right: 0; top: 30px;
    }
  }
  .chart-box {
    padding: 0 0 30px 0;
    .chart-wrap {
      margin: 20px 0 0 0;
      & > div:nth-child(1) { padding: 0 15px 0 0 }
      & > div:nth-child(2) { padding: 0 0 0 15px }
      .chart {
        background: #FFFFFF; width: 100%;
        & > div { width: 800px; height: 300px; }
      }
    }
    .table-box{
      background: #FFFFFF; padding: 0 0 10px 0;
    }
  }
  .detail-total{
    font-size: 14px; color: #666666;
    i { color: red; font-style: normal }
  }
  .el-table{
    .el-image{ width: 60px; height: 60px; margin: 0 3px; border: #CCCCCC solid 1px; }
  }
}
    .tab-header{
      background: #D6E8FB;
      .tab-body-item{
         margin: 0 -1px -1px 0;
      }
    }
    .tab-body{
      // border:1px solid #CCC;
      background:#FFF;
      .tab-body-item{
         border:1px solid #CCC;
         margin: 0 -1px -1px 0;
         overflow: hidden;
      }
    }
    .tab-body,.tab-header{
      .tab-body-item{
        padding:10px;
      }
    }
</style>
