import request from "@/api/service";

const api = {
   total: '/pms/receiveSituation/total',
   getSumFormData:'/pms/receiveSituation/sumFormData',
   // 收款汇总导出
   processRecordExcel:'/pms/collectionRefund/getCollectionRecordExcel',
   processRecord:'/pms/collectionRefund/getCollectionRecord',

   exportSummaryExcel:'/pms/receiveSituation/exportSummary'
}

export function getTotal(params) {
  return request({
     url: api.total,
     method: 'POST',
     data: params
  })
}

export function getSumFormData( params) {
  return request({
     url: api.getSumFormData,
     method: 'POST',
     data: params
  })
}

export function processRecordExcel(params){
   return request({
      url: api.processRecordExcel,
      method: 'POST',
      data:params,
      responseType: 'blob',
   })
}

export function processRecord(params){
   return request({
      url: `${api.processRecord}?page=${params.page}&limit=${params.limit}`,
      method: 'POST',
      data:params,
   })
}

export function exportSummaryExcel(params){
   return request({
      url:api.exportSummaryExcel,
      method:'get',
      responseType: 'blob',
      params
   })
}